import PropTypes from "prop-types";
import cn from "classnames";
import { getArtworkPath } from "api";
import { Image, Link, Icon } from "components/basic";
import Blurhash from "components/basic/Blurhash";
import { getArtworkUrl, AUTH_MODAL_STATE, IMAGE_TYPE, ENTITY_TYPES, REG_PROMPT_TRIGGERS } from "const";
import { useStore } from "store";
import { observer } from "mobx-react-lite";
import dynamic from "next/dynamic";
import ae from "lib/analytics";

const BoardsTag = dynamic(() => import("./OverlayItems/BoardsTag"));
const Like = dynamic(() => import("./OverlayItems/Like"));
const AddToBoard = dynamic(() => import("./OverlayItems/AddToBoard"));

const DEFAULT_ARTWORK_BG_COLOR = "#e6e6e6";

const Overlay = observer(function Overlay({ children, alwaysVisible, padding, className }) {
  return (
    <div
      className={cn(
        "overlap-item pointer-events-none z-0",
        className ?? "duration-150 ease-in group-hover:bg-black-100 group-hover:bg-opacity-10"
      )}
    >
      <div
        className={cn("flex h-full flex-col text-white-100", padding, {
          "bg-black-100 bg-opacity-10": alwaysVisible,
          "scale-95 opacity-0 duration-150 ease-in group-hover:scale-100 group-hover:opacity-100": !alwaysVisible,
        })}
      >
        {typeof children === "function" ? children({ AddToBoard, BoardsTag, Like }) : children}
      </div>
    </div>
  );
});

Overlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  alwaysVisible: PropTypes.bool,
  padding: PropTypes.string,
  className: PropTypes.string,
};

Overlay.defaultProps = {
  alwaysVisible: false,
  padding: "p-12",
  className: null,
};

function Artwork({
  artwork,
  url,
  beforeNav,
  className,
  children,
  animation,
  link,
  target,
  sizes,
  nativeAspectRatio,
  style,
  ...props
}) {
  const artworkPage = useStore("/pages/artworkPage");
  const { userData, isLoggedIn } = useStore("/user");
  const { sensitiveContentModal, authModal } = useStore("/ui");

  if (!artwork) return <div className={className} style={{ backgroundColor: DEFAULT_ARTWORK_BG_COLOR }} />;

  const { id, title, artist, filename, width, height, mainColor, blurHash, ratio, sensitiveContent } = artwork;
  const src = getArtworkPath(filename);

  const navigationAction = target ? null : beforeNav || (() => artworkPage.mount(id));

  if (userData.settings.hideSensitiveContent && sensitiveContent)
    return (
      <div
        data-sensitive="true"
        data-blurhash={blurHash}
        className={cn("overlap-container group overflow-hidden text-white-100 @container", className)}
        style={nativeAspectRatio ? { aspectRatio: String(ratio) } : null}
      >
        {/* hidden link element for editor to catch up artwork's source url */}
        {/* ArtworkTool:153 */}
        <Link href={getArtworkUrl(id, title)} className="hidden" rel="canonical" />

        <div className="overlap-item h-full w-full overflow-hidden">
          <Blurhash hash={blurHash} className="h-full w-full" />
        </div>
        <div className="overlap-item z-0 flex flex-col items-center justify-center space-y-12 p-10">
          <button
            type="button"
            className="font-semibold hover:underline"
            onClick={() => {
              if (!isLoggedIn) {
                authModal.setState(AUTH_MODAL_STATE.REGISTER_TO_CONTINUE, {
                  type: ENTITY_TYPES.ARTWORK,
                  id,
                  action: REG_PROMPT_TRIGGERS.SENSITIVE_CHANGE,
                });
                authModal.setOpen(true);
                return;
              }

              sensitiveContentModal.setOpen(true);
              ae.sensitive.open(userData.settings.hideSensitiveContent, IMAGE_TYPE.ARTWORK, id);
            }}
          >
            <Icon icon="alert" className="h-30 w-30 flex-shrink-0" />
          </button>

          <p className="hidden max-w-[22rem] text-center text-12 leading-tight @150:block">
            Following content may not be suitable for some audiences.
          </p>

          <button
            type="button"
            className="hidden font-semibold hover:underline @80:block"
            onClick={() => {
              if (!isLoggedIn) {
                authModal.setState(AUTH_MODAL_STATE.REGISTER_TO_CONTINUE, {
                  type: ENTITY_TYPES.ARTWORK,
                  id,
                  action: REG_PROMPT_TRIGGERS.SENSITIVE_CHANGE,
                });
                authModal.setOpen(true);
                return;
              }

              sensitiveContentModal.setOpen(true);
              ae.sensitive.open(userData.settings.hideSensitiveContent, IMAGE_TYPE.ARTWORK, id);
            }}
          >
            View
          </button>
        </div>
      </div>
    );

  return (
    <div
      className={cn("overlap-container group overflow-hidden", className)}
      style={{ ...style, aspectRatio: nativeAspectRatio ? String(ratio) : null }}
    >
      <Image
        color={mainColor || DEFAULT_ARTWORK_BG_COLOR}
        blurHash={blurHash}
        width={width}
        height={height}
        alt={[artist && `${artist.name.trim()} art`, title.trim()].filter(v => v).join(", ")}
        styles={{
          container: cn("overlap-item", nativeAspectRatio && "w-auto mx-auto"),
          image: cn(
            "opacity-0 select-none pointer-events-none",
            animation && "duration-150 ease-in group-hover:scale-105",
            artwork.isNativeAspectRatio ? "!object-contain opacity-100" : "object-cover opacity-100"
          ),
        }}
        ratio={nativeAspectRatio ? ratio : null}
        src={src}
        srcSet={sizes?.srcSet.replaceAll("{url}", src)}
        sizes={sizes?.sizes}
        onLoad={e => e.target.classList.remove("opacity-0")}
        {...(link
          ? { as: Link, href: url || getArtworkUrl(id, title), beforeNav: navigationAction, target, rel: "canonical" }
          : null)}
        {...props}
      />
      {children}
    </div>
  );
}

Artwork.propTypes = {
  artwork: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    filename: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    mainColor: PropTypes.string,
    blurHash: PropTypes.string,
    ratio: PropTypes.number,
    artist: PropTypes.shape({
      name: PropTypes.string,
    }),
    previewBoard: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    stats: PropTypes.shape({
      amountOfBoards: PropTypes.number,
    }),
    sensitiveContent: PropTypes.bool,
    isNativeAspectRatio: PropTypes.bool,
  }),
  sizes: PropTypes.shape({
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
  }),
  url: PropTypes.string,
  target: PropTypes.string,
  beforeNav: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape(),
  link: PropTypes.bool,
  animation: PropTypes.bool,
  children: PropTypes.node,
  nativeAspectRatio: PropTypes.bool,
};

Artwork.defaultProps = {
  artwork: null,
  className: null,
  style: null,
  children: [],
  url: null,
  target: null,
  beforeNav: null,
  link: true,
  animation: true,
  sizes: null,
  nativeAspectRatio: false,
};

Artwork.Overlay = Overlay;

export default observer(Artwork);
